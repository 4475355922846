.text-format {
    white-space: nowrap; 
	width: 50px; 
	overflow: hidden;
	text-overflow: ellipsis;
}

.build-resp-table {
    width: 100%;
    border-collapse: collapse;
}

.build-resp-table tr th {
    font-size: 14px;
    /*border-bottom: 1px solid #d8d5d5;*/
}

.build-resp-table tr td {
    text-align: center;  
    /*padding-top: 10px;*/
    vertical-align: top;
    width: 14% !important;
}

/*.build-resp-table tr td span:nth-child(2) {
    padding-left: 30px;
}*/

.build-resp-table tr td div {
    display: inline-block;
    font-size: 11px;
}
.build-resp-table tr td table {
    width: 100%;
}

.build-resp-table tr td div:nth-child(2) {
    padding-left: 30px;
}

/*.build-resp-table tr td div.help-text {
    font-size: 11px;
    display: inline;
}*/

.status-text {
    font-size: 12px;
}

.MuiInputBase-input, .MuiMenuItem-root {
    font-size: 14px !important;
}

.MuiAppBar-colorPrimary {
    background: linear-gradient(to left,#384f69,#828282);
}

.inprogress-btn {
    animation: blinkingBg 3s infinite;
}

.MuiTab-root {
    min-height: 35px !important;
}

.searchText {
    text-shadow: -1px -1px 1px #fff, 1px 1px 1px #000;
    color: #bdbdbd;
    opacity: 0.2;
    font: 60px 'Museo700';
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@keyframes blinkingBg {
    0%	 {background: #17a2b8;}
    25%	 {background: #1056c0;}
    50%	 {background: #17a2b8;}
    75%	 {background: #227c9e;}
    100% {background: #04a1d5;}
}
      