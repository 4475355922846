html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f6f6;
  background-image: radial-gradient(#dfe5ea 10%, transparent 0);
  background-size:20px 20px;
  
}

a {
  text-decoration: none;
}
